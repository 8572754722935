<template>
  <div class="app-container">
    <template v-if="!messageVisible">
      <div class="tips">
        如果添加不上助教微信，请填写您的手机号，我们会 有助教去联系您！
      </div>
      <div class="submit-box">
        <div class="title">确保填写正确的手机号</div>
        <div class="input-box">
          <input v-model="phone" type="phone" class="phone" placeholder="请输入您的手机号">
          <div class="submit-btn" @click="submit">提交</div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="success-icon" />
      <div class="success-text">手机号已提交，等待助教与您联系</div>
      <router-link tag="div" class="success-back-btn" :to="{name: 'WechatLanding', params: {id: $route.query.goodsId}}">返回</router-link>
    </template>
  </div>
</template>
<script>
import {Landing} from '@/network'
import {Toast} from 'vant'

export default {
  data() {
    return {
      phone: null,
      messageVisible: false
    }
  },
  methods: {
    submit() {
      if (!/^1[3-9]\d{9}$/.test(this.phone)) return Toast('请输入正确的手机号')
      const {code} = Landing.submitPhone({
        outTradeNo: this.$route.query.outTradeNo,
        phone: this.phone
      })
      if (!code) return (this.messageVisible = true)
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #f4f4f4;
}
.tips {
  width: 331px;
  line-height: 23px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  margin: 20px 0;
}
.submit-box {
  width: 292px;
  height: auto;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 32px 0;
  .title {
    width: auto;
    line-height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    margin-bottom: 40px;
  }
  .input-box {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .phone {
      width: 114px;
      height: 26px;
      line-height: 26px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      border: none;
      border-bottom: 1px solid #f4f4f4;
      &::placeholder {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
    }
    .submit-btn {
      width: 80px;
      height: 26px;
      background: #f84439;
      border-radius: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 17px;
    }
  }
}
.success-icon {
  width: 82px;
  height: 64px;
  background-image: url('~@/assets/images/success-submit-phone-icon.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 48px 0 24px;
}
.success-text {
  width: auto;
  height: 18px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 18px;
  margin-bottom: 30px;
}
.success-back-btn {
  width: 78px;
  height: 22px;
  border-radius: 20px;
  border: 1px solid #f84439;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f84439;
  line-height: 17px;
}
</style>
